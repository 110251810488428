import { HudGlobal } from "../../util/HudGlobal";
import defaultHead from "../../assets/img/default-head.png";
import WebCamJS from "@tibi/webcam";
import socketjs from "@tibi/socket";
import DateUtil from "../../util/DateUtil";
import aliOssServe from "../../util/aliOssServe";
import hoursApi from "../../api/hours/hoursApi";
import QrCodeLogo from "../../components/vue-qrcode/QRCode.vue";
import planApi from "../../api/planApi";
import faceApi from "../../api/face/faceApi";
import { Constants } from "../../util/Constants";
import OfflineParam from "../../util/OfflineParam";
import TakePhotoDialog from "../../components/TakePhotoDialog.vue";
import StoreTrainDBManger from "../../db/StoreTrainDBManger";
import HomeGuideSteps from "@/util/guide-step/HomeGuideSteps";
import GuideStepHelper from "@/util/guide-step/GuideStepHelper";
import ConstantsGuideCode from "@/util/constants/ConstantsGuideCode";
import TBDriver from "@tibi/vue-driver/TBDriver.js";
import CameraUtil from "../../util/CameraUtil";
import StoreDBManger from "../../util/StoreDBManger";
import TibiMatomoManger from "../../util/manager/TibiMatomoManger";
import TrackConstants from "../../util/constants/TrackConstants";
import LoginOutDialog from "./components/login-out-dialog/LoginOutDialog.vue";
import { mapState } from "vuex";
import GlobalHelper from "../../util/GlobalHelper.js";
import store from "../../store/store";
import { gotoHomeList } from "../middle-ware/utils/ControlRouter";
import { getCurrentSystemType } from "../../util/user/UserManger";
import EnumConstants, { SYSTEM_TYPE } from "../../util/constants/EnumConstants";
import { getOfflineRecordIdFromDB, setOfflineRecordIdToDB } from "../../util/db/TrainDBManger";
import { COMMON } from "../../util/constants/RouteConstant";
import { getQueryStringParams } from "../../util/helper/URLHepler";
import { showTimerDialog } from "../../util/windowAlert.js";
import { gotoCloseWindow } from "../../util/http/HandleHttp.js";

export default {
    name: "PrepareTraining",
    components: {
        QrCodeLogo,
        TakePhotoDialog,
        LoginOutDialog,
    },
    data() {
        return {
            // 人脸图片限制
            faceImageLimit: {
                min: 0,
                max: 1024,
            },
            routeParam: {
                type: getCurrentSystemType(),
                meetingSelectType: 0,
                meetingCourseId: null,
            },
            loading: false,
            takePhotoLoading: false,
            // 学员数据总数
            tableDataTotal: 0,
            // 学员及管理人员数据
            tableData: [],
            // 未签到管理人员数据, 如果是同一人会进行合并, 身份展示多个
            tableLeadersData: [],
            // 上面的数据
            tableLeadersSrcData: [],
            // 未签到学员数据
            tableStudentData: [],
            dialogTableVisible: false,
            // 这个值是从其它地方传过来的, 现在不需要了,
            planData: {},
            // 现场培训记录信息
            offlineRecordData: {},
            offlineRecordParms: {
                offlineRecordId: "",
            },
            // 学员签到等参数
            studentListParams: {
                // 获取未签到抽验签退记录0, 获取已签到抽验签退记录1
                operateCode: 0,
                currentPage: 1,
                pageSize: 12,
                pagerCount: 5,
                // 岗位编码
                dutyCode: "",
                // 计划ID
                planId: "",
                // 姓名或者证件号码
                realNameOrCardNo: "",
                // 搜索,关键字检索（姓名、手机号码、身份证号）
                searchVal: "",
                // 签到类型（0:待签到，10：已签到，20：抽验，30：签退）
                signType: 0,
                // 现场学习培训记录ID
                offlineRecordId: "",
                // 现场学习教室id
                classroomId: "",
                userId: "",
                validateNumber: "",
            },
            // 培训管理人员参数
            leadersListParams: {
                // 计划ID
                planId: "",
                orgId: "",
                // 状态（10：未签到，20：已签到,30:已签退）
                state: 10,
                // 现场学习培训记录ID
                offlineRecordId: "",
                // 现场学习教室id
                classroomId: "",
                // 监督人 userId
                userId: "",
                // 身份类型学员(system_org_indentity_student)，考核人员(system_org_indentity_assessors)，
                // 安全管理员(system_org_indentity_admin_safe)
                identityCode: "",
                // 现场学习客户端编号
                clientNumber: "",
            },
            // 已签到的记录
            signInLogList: {
                currentPage: 1,
                pageSize: 10,
                pagerCount: 5,
                // 签到人数量
                signInNumber: 0,
                // 余下数量
                signInRestNumber: 0,
                // 所有人员签到记录
                list: [],
            },
            // 签到人员信息
            signUserInfo: {
                defaultHead: defaultHead,
                // 需要签到的用户信息
                srcUserInfo: "",
                destUserFaceUrl: "" || defaultHead,
                signInStatus: "请签到",
                signInTableText: "签到",
                // 签到的照片
                signInPhoto: null,
            },

            /*
            签到界面的操作类型, 0或空为签到, 1抽验, 2签退
            */
            signType: {
                // 抽验是否提示
                signCheckTip: false,
                signType: 0,
                signTypeName: "签到", // 0或空为签到, 1抽验, 2签退
            },
            // 签到方式
            signInType: {
                // 0开启, 1关闭
                pcSignIn: 0,
                pcSignInName: "关闭电脑验证",
                phoneSignIn: 0,
                phoneSignInName: "关闭手机验证",
                // 签到二维码
                qrcodeImage: "",
                qrcodeImage2: "",
            },
            // 摄像头配置
            webcamConfig: {
                width: 180,
                height: 229,
                dest_width: 180,
                dest_height: 229,
                crop_width: 210,
               crop_height: 340,
               image_format: "png",
               jpeg_quality: 100,
                force_flash: false,
                flip_horiz: true,
                fps: 60,
                upload_name: "webcam", // name of file in upload post data
                constraints: null, // custom user media constraints,
                swfURL: "webcam.swf", // URI to webcam.swf movie (defaults to the js location)
                flashNotDetectedText: "您的电脑没有检测到安装flash",
                noInterfaceFoundText: "没有检测到摄像头",
                unfreeze_snap: true, // Whether to unfreeze the camera after snap (defaults to true)
            },
            // 签到表单
            signInForm: {
                faceUrl: "",
                // 学习记录id
                // hoursRecordId: "",
                // 现场学习ID
                offlineRecordId: "",
                // 现场学习教室id
                classroomId: "",
                //  身份类型编码（已知的情况下传，A8PP扫码情况可以不传）(学员(system_org_indentity_student)，
                // 考核人员(system_org_indentity_assessors)，安全管理员
                // identityCode: "system_org_indentity_student",
                ipAddr: "127.0.0.1",
                // 10:签到 21：抽验，91：安全管理员签退， 92：考核人员签退， 93：现场学习按比例签退（暂不考虑），
                // 94:现场学习正常全部签退（不需要人像验证情况）， 95:退出现场学习， 100：单个学员结束学习（签退），
                operationType: 10,
                orgId: "",
                userId: "",
                realName: "",
                // 签到来源（10：pc,20：Android，21：iOS，22：公众号，23小程序)
                signSource: 10,
                // 学习场景（10：pc网络，14：现场考试，15：pc现场学习，20：Android，21：iOS，22：公众号，23：小程序）
                sourceType: 15,
                planId: "",
                // trainingId: "",
                parameterMap: null,
            },
            // 手机二维码签到
            qrcodeSignIn: {
                event: {
                    receive: "training.online.message",
                    send: "training.online",
                },
                // 请求签到二维码的参数
                params: {
                    userId: this.$store.getters.userId,
                    // 签到来源（10：pc,20：Android，21：iOS，22：公众号，23小程序)
                    signSource: 10,
                    // 学习场景（10：pc网络，14：现场考试，15：pc现场学习，20：Android，21：iOS，22：公众号，23：小程序）
                    sourceType: 15,
                    planId: "",
                    offlineRecordId: "",
                    classroomId: "",
                    /*
                                 50：签到二维码通知开始，51：抽验二维码通知开始，52：签退二维码通知开始,53：
                                 签到二维码结束通知，54：抽验二维码结束通知，55：签退二维码结束通知，56，二维码有效检测
                               */
                    operationType: 50,
                },
            },
            /**
             *   参数二维码
             errorCorrectionLevel, 容错级别, low, medium, quartile, high or L, M, Q, H.
             maskPattern, 掩码大小, 0, 1, 2, 3, 4, 5, 6, 7
             width, 宽度, 数字
             color.dark 背景色
             color.light 前景色
             type 生成的图片类型, image/png, image/jpeg, image/webp
             rendererOpts.quality 生成的图片质量, 默认0.92, 值在0-1之间, 注意PNG没有质量
             */
            // 二维码配置项
            opts: {
                errorCorrectionLevel: "H",
                type: "image/jpeg",
                rendererOpts: { quality: 0.8 },
                width: 244,
            },
            // 二维码内容
            qrCodeContent: "",
            wechatQrCode: "", //底部二维码
            // 二维码LOGO
            logoImg: require("@assets/logo.png"),
            // 开始上课
            startToClazz: false,
            /*
                      界面控制参数, 签到的相关参数
                      // faceAuthEnable

                      // faceLiveDetectionPc
                      // faceLiveDetectionApp
                      offlineStartFaceAuthEnable
                      // offlineLeaderJoinSignEnable

                      offlineLeaderSigninEnable
                      offlineAdminSafeSigninEnable
                      offlineAssessorsSigninEnable
                      offlineLecturerSigninEnable

                      offlineLeaderSigninBeforeStudent
                    */
            signCtrlParam: {
                // 是否需要人像验证, 后台参数, 暂时不替换, 后台已经有验证
                isFaceVerify: true,
                faceLiveDetectionPc: 0,
                faceLiveDetectionApp: 0,
                offlineStartFaceAuthEnable: 0,
                // 安全管理员签退是否强制签退未签退学员  offlineAdminSignoutHoursEnable  1 0:否, 1(默认)是
                offlineAdminSignoutHoursEnable: 0,
                // 考核人员签退是否强制签退未签退学员  offlineAssessorsSignoutHoursEnable 0:否, 1(默认)是
                offlineAssessorsSignoutHoursEnable: 0,
                // 授课人签退是否强制签退未签退学员  offlineLecturerSignoutHoursEnable 0:否, 1(默认)是
                offlineLecturerSignoutHoursEnable: 0,
            },

            // 方法锁定不重复运行
            signOutToHomeListFun: false,
            // 抽验完成提示弹窗提类型, 0无, 1所有抽验完成提示, 2当前抽验过期提示
            signCheckFinishedTipType: 0,

            // 统计人数
            staticObject: {
                // 待签到/签退/抽验人数
                waitOperationCount: 0,
                waitOperationLeaderCount: 0,
                waitOperationStudentCount: 0,

                //完成操作签到/签退/抽验人数
                finishOperationCount: 0,
            },
            logOutDialog: {
                logOutDialogVisible: false, // 退出现场学习弹窗
                msg: "",
            },

            dataURL: "",
            // 引导id
            operationGuideListId: [],
            isFristEntry: true, // 是否是第一次进入页面
            isMove: false,
            notice: "",
            nextLoading: false, // 下一步的loading P1.0.28 lzb 2023-1124
        };
    },
    mounted() {
        this.isFristEntry = true;
        let query = this.$route.query;
        console.log("参数:", query);

        // 设定当前认证的场景 签到界面的操作类型, 0或空为签到, 1抽验, 2签退
        this.initSignType();

        this.initData(query);

        Object.assign(this.routeParam, query);
        //
        this.getOperationGuideList();

        // 获取现场培训基本信息
        this.getOfflineRecordDataOne();

        // todo 如果要测试拍照，要打开这个代码 通过环境配置来
        if (process.env.VUE_APP_OPEN_PC_FACE == "true") {
            if (this.signInType.pcSignIn === 0) {
                this.initWithCamera("signInUserBox");
            }
        } else {
            // 现场会议处理 P1.0.28 lzb 20231129
            if (this.isOfflineMeeting) {
                this.handleSignInType("pc");
            } else {
                if (this.signInType.pcSignIn === 0) {
                    this.initWithCamera("signInUserBox");
                }
            }
        }

        this.setLogoImg();

        // 异步加载上传配置
        this.loadUploadConfigRequest();
    },

    destroyed() {
        // 关闭相机
        CameraUtil.closeCamera();
        // 清除发送
        this.closeQrcodePush();
        socketjs.close();
        socketjs.destroy();
    },
    beforeDestroy() {
        // 关闭相机
        CameraUtil.closeCamera();
    },

    methods: {
        /**fbz 2021-07-28
         * @description: 系统参数配置二维码logo
         * @param {*}
         * @return {*}
         */
        setLogoImg() {
            const that = this;
            if (this.parameterCodeList.study_qrcode_logo) {
                const temp = JSON.parse(this.parameterCodeList.study_qrcode_logo);
                GlobalHelper.getBase64Image(temp[0].parameterName, (dataURL) => {
                    that.logoImg = dataURL;
                });
            }
            // lzb 2023-11-27 P1.0.28
            if (this.isOfflineMeeting) {
                if (!!this.parameterCodeList.safe_meeting_offline_signin_notice) {
                    const tempList = JSON.parse(this.parameterCodeList.safe_meeting_offline_signin_notice);
                    const temp = tempList.map((item) => item.parameterName);
                    that.setNoticeBar(temp);
                }
            } else {
                // lzb 2022-01-06 修改重参数中来
                OfflineParam.getCurretPlanParamConfigFromDB(function(planParam) {
                    if (!!planParam.offlineSigninNotice) {
                        const tempList = JSON.parse(planParam.offlineSigninNotice);
                        const temp = tempList.map((item) => item.parameterName);
                        that.setNoticeBar(temp);
                    }
                });
            }
        },
        /**
         * 设置滚动提示类容
         * @param {*} list
         */
        setNoticeBar(list) {
            let i = 0;
            const that = this;
            that.notice = list[i];
            that.isNoticeBarMove();
            let timer = setInterval(() => {
                i === list.length - 1 ? (i = 0) : i++;
                that.notice = list[i];
                that.isNoticeBarMove();
            }, 1000 * 30);
            this.$once("hook:beforeDestroy", () => {
                clearTimeout(timer);
                timer = null;
            });
        },
        /**
         * 提示是否滚动
         */
        isNoticeBarMove() {
            const that = this;
            that.isMove = false;
            that.$nextTick(() => {
                if (that.$refs["notice-box"].clientWidth < that.$refs["notice-bar"].clientWidth) {
                    that.isMove = true;
                }
            });
        },
        /*
         * 初始化页面
         * */
        initData(query) {
            // 重要参数, offlineRecordId
            Object.assign(this.planData, query);

            // 现场培训记录ID
            let offlineRecordId;
            if (query.offlineRecordId) {
                offlineRecordId = query.offlineRecordId;
            } else {
                offlineRecordId = getOfflineRecordIdFromDB();
            }
            this.offlineRecordParms.offlineRecordId = offlineRecordId;

            if (query.validateNumber) {
                this.studentListParams.validateNumber = query.validateNumber;
            } else {
                this.studentListParams.validateNumber = "";
            }

            // 全局界面标识 plan prepare sign play
            window.localStorage.setItem("currentStep", "sign");

            this.signType.signCheckTip = false;

            // 初始化配置参数
            const that = this;
            OfflineParam.getCurretPlanParamConfigFromDB(function(planParam) {
                that.setupInitPlanParam(planParam);
            });

            // 参数处理, 人像
            if (!this.signCtrlParam.isFaceVerify) {
                this.signInType.phoneSignIn = 0;
                this.signInType.pcSignIn = 1;
            }
        },

        /*
         * 设定当前认证的场景 签到界面的操作类型, 0或空为签到, 1抽验, 2签退
         * */
        initSignType() {
            // let signType = window.localStorage.getItem("signType");
            //代码合并掉了 fbz-2020-09-14 重新提交
            let signType = StoreDBManger.getDataWithLocalStorage(StoreDBManger.storageKeys.SELECT_SIGN_TYPE);

            if (signType) {
                signType = parseInt(signType);
                this.signType.signType = signType;

                let signTypeName1 = this.getSignTypeName(signType);
                this.signType.signTypeName = signTypeName1;
            }
        },

        /*
         * 获取签到类型名称
         * */
        getSignTypeName(signType) {
            if (!signType) {
                signType = StoreDBManger.getDataWithLocalStorage(StoreDBManger.storageKeys.SELECT_SIGN_TYPE);
                // signType = window.localStorage.getItem("signType");
                signType = parseInt(signType);
            }

            let signTypeName = "签到";
            switch (signType) {
                case 0:
                    signTypeName = "签到";
                    this.signUserInfo.signInStatus = "请签到";
                    this.signUserInfo.signInTableText = "签到";
                    break;
                case 1:
                    signTypeName = "抽验";
                    this.signUserInfo.signInStatus = "请抽验";
                    this.signUserInfo.signInTableText = "抽验";
                    break;
                case 2:
                    signTypeName = "签退";
                    this.signUserInfo.signInStatus = "请签退";
                    this.signUserInfo.signInTableText = "签退";
                    break;
                default:
                    signTypeName = "签到";
            }
            return signTypeName;
        },

        //================================================二维码===========================================//
        /*
         * 初始化socket
         * */
        initSocket() {
            // 初始化
            let userId = this.$store.getters.userId;
            let url = process.env.VUE_APP_SOCKET_URL;
            const tempId = localStorage.getItem("tempId");
            const offlineRecordId = getOfflineRecordIdFromDB();
            socketjs.init({
                url: url,
                userId: `${userId}&sourceType=15&tempId=${tempId}&offlineRecordId=${offlineRecordId}`,
                // 不默认连接
                createConnection: false,
            });

            if (this.signInType.phoneSignIn === 0) {
                this.signInForPhone();
            }
        },

        /*
         * 请求手机二维码签到
         * */
        signInForPhone() {
            // 解析二维码 QrCode
            let _this = this;
            socketjs.on(this.qrcodeSignIn.event.receive, function(data) {
                console.log("接收到=socket数据---signType=%s---------operationType=%s", _this.signType.signType, data.content.operationType);

                if (data.content) {
                    let content1 = data.content;
                    let operationType = content1.operationType;

                    let signType = _this.signType.signType;
                    if ((signType === 0 && operationType === 50) || (signType === 1 && operationType === 51) || (signType === 2 && operationType === 52)) {
                        let qrCodeContent = content1.qrCodePushContentDto;
                        if (qrCodeContent) {
                            let contentStr = JSON.stringify(qrCodeContent);
                            _this.qrCodeContent = contentStr;
                            _this.refreshRightQR();
                        }
                    } else if (
                        (signType === 0 && operationType === 10) ||
                        (signType === 1 && operationType === 21) ||
                        (signType === 2 && operationType === 91) ||
                        (signType === 2 && operationType === 92) ||
                        (signType === 2 && operationType === 100)
                    ) {
                        //
                        console.log("手机--签到/抽验/签退的学员列表,", content1.validateUserList);
                        if (content1.validateUserList) {
                            // 签到/抽验/签退的学员列表处理
                            for (let i = 0; i < content1.validateUserList.length; i++) {
                                let validateUser = content1.validateUserList[i];
                                _this.signInLogList.list.unshift(validateUser);
                                // 改变手机完成的人数
                                _this.signInLogList.signInNumber++;
                                // 计算完成人数
                                _this.staticObject.finishOperationCount++;

                                //计算待操作人签到/签退/抽验
                                _this.computeUserCountArrayListByUser(_this.tableData, validateUser);

                                _this.tableData = _this.filterArrayListByUser(_this.tableData, validateUser);
                                // 如果是培训管理人员一样也要移除掉
                                _this.tableLeadersData = _this.filterArrayListByUser(_this.tableLeadersData, validateUser);

                                // 重置签到区 2020-03-12 lzb修改
                                _this.clearSignStatus();
                            }
                        } else {
                            // 如果是开始上课验证, 那么完成后跳转上课页面
                            if (_this.startToClazz) {
                                _this.startToLesson();
                            }
                        }
                    } else if (operationType === 70) {
                        // 所有抽验完成通知
                        _this.signCheckFinishedTipType = 1;
                        _this.signCheckFinishedTip();
                    } else if (operationType === 101) {
                        // 现场学习所有签退完成，结束培训
                        _this.signOutToHomeList();
                    } else if (operationType === 201) {
                        // 开始上课跳转上课页面通知
                        _this.startToLesson();
                    } else if (operationType === 501) {
                        let message = "您已经在其它地方登陆学习!";
                        if (content1.hasOwnProperty("msg")) {
                            message = content1.msg;
                        }
                        showTimerDialog({
                            message,
                            confirmButtonText: "返回计划",
                            cancelButtonText: "关闭窗口",
                        })
                            .then((res) => {
                                _this.toHomeList();
                            })
                            .catch(() => {
                                gotoCloseWindow();
                            });
                    }
                }
            });

            this.openQrcodePush();
        },

        /*
         * 开始推送二维码
         * */
        openQrcodePush() {
            // 签到界面的操作类型, 0或空为签到, 1抽验, 2签退
            /*
                     50：签到二维码通知开始，51：抽验二维码通知开始，52：签退二维码通知开始,
                     53：签到二维码结束通知，54：抽验二维码结束通知，55：签退二维码结束通知，TODO 未发结束二维码, 离开页面应该发送
                     56，二维码有效检测
                   */
            let operationType = 50;
            switch (this.signType.signType) {
                case 0:
                    this.qrcodeSignIn.params.operationType = 50;
                    break;
                case 1:
                    this.qrcodeSignIn.params.operationType = 51;
                    break;
                case 2:
                    this.qrcodeSignIn.params.operationType = 52;
                    break;
                default:
                    this.qrcodeSignIn.params.operationType = 50;
            }

            operationType = this.qrcodeSignIn.params.operationType;
            this.sendQrcode(operationType);
        },

        /*
         * 关闭二维码推送
         * */
        closeQrcodePush() {
            // 签到界面的操作类型, 0或空为签到, 1抽验, 2签退
            /*
                     50：签到二维码通知开始，51：抽验二维码通知开始，52：签退二维码通知开始,
                     53：签到二维码结束通知，54：抽验二维码结束通知，55：签退二维码结束通知，TODO 未发结束二维码, 离开页面应该发送
                     56，二维码有效检测
                   */
            let operationType = 53;
            switch (this.signType) {
                case 0:
                    this.qrcodeSignIn.params.operationType = 53;
                    break;
                case 1:
                    this.qrcodeSignIn.params.operationType = 54;
                    break;
                case 2:
                    this.qrcodeSignIn.params.operationType = 55;
                    break;
                default:
                    this.qrcodeSignIn.params.operationType = 53;
            }

            this.sendQrcode(operationType);

            // 最终中断连接, 关闭二维码不关闭通信
            // setTimeout(function () {
            //     socketjs.close();
            // }, 22);
        },

        /**
         * 刷新右侧二维码 lzb 20230810
         */
        refreshRightQR() {
            const that = this;

            // 刷新右侧二维码
            that.$refs.qrCodeLogoBox &&
                that.$refs.qrCodeLogoBox.toQrCode(
                    that.qrCodeContent,
                    () => {
                        console.log("右侧二维码更新成功");
                    },
                    (code) => {
                        console.log("右侧二维码更新失败----code=%s", code);
                        // 宏任务刷新二维码
                        setTimeout(() => {
                            that.refreshRightQR();
                        }, 500);
                    }
                );

            // 手机二维码
            if (that.signInType.pcSignIn === 1 && that.signInType.phoneSignIn === 0) {
                that.$refs.qrCodeLogoBox1 && that.$refs.qrCodeLogoBox1.toQrCode(that.qrCodeContent);
            }
        },

        /*
         * 推送签到二维码
         * */
        sendQrcode(operationType) {
            this.qrcodeSignIn.params.offlineRecordId = this.planData.offlineRecordId;
            this.qrcodeSignIn.params.planId = this.planData.planId;

            // lzb20200409 this.planData.classroomId 接口可能为null
            if (this.planData.classroomId) {
                this.qrcodeSignIn.params.classroomId = this.planData.classroomId;
            } else {
                const key = this.planData.offlineRecordId + "classRoomId";
                this.qrcodeSignIn.params.classroomId = window.localStorage.getItem(key);
            }

            // 签到界面的操作类型, 0或空为签到, 1抽验, 2签退
            /*
                     50：签到二维码通知开始，51：抽验二维码通知开始，52：签退二维码通知开始,
                     53：签到二维码结束通知，54：抽验二维码结束通知，55：签退二维码结束通知，TODO 未发结束二维码, 离开页面应该发送
                     56，二维码有效检测
                   */
            this.qrcodeSignIn.params.operationType = operationType;

            let params = {};
            let content = {};
            Object.assign(content, this.qrcodeSignIn.params);

            params.content = content;
            params.userId = this.$store.getters.userId;

            // lzb 2020-06-23 V1.0.9 后台需要
            params.sourceType = 15;
            console.log("发送socket---operationType=%s--请求参数", operationType, params);
            socketjs.send(this.qrcodeSignIn.event.send, params);
        },
        /*
         * 更改用户人像
         * */
        updateUserFace() {
            TibiMatomoManger.sendSignInTrackEvent(TrackConstants.eventNameKeys.sign_in_menu_change_face);
            let srcUserInfo = this.signUserInfo.srcUserInfo;
            if (srcUserInfo) {
                let msg =
                    "您确定修改 <span style='color: red'>" +
                    srcUserInfo.realName +
                    "(" +
                    srcUserInfo.cardNo +
                    ") </span>的注册照吗? 如果系统自动审核未通过, 将进入人工审核, 请您耐心等待!";
                let _this = this;
                HudGlobal.showAlertConfirmMessages(msg).then(() => {
                    _this.$refs.takePhotoDialog.show();
                });
            } else {
                HudGlobal.showAlertMessage("您还没有人员哟!");
            }
        },

        /**
         *  拍照图片回调
         */
        hanldeTakePhotoCallBackEvent(item) {
            let that = this;
            // 点击确定
            if (item.type === 1) {
                const file = item.data.file;
                that.loadUploadImageRequest(file, 1)
                    .then(function(response) {
                        let param = {};
                        param.userId = that.signUserInfo.srcUserInfo.userId;
                        // yxl V1.0.9 20210712 新增入参
                        param.orgId = that.signUserInfo.srcUserInfo.orgId;
                        param.orgName = that.signUserInfo.srcUserInfo.orgName;
                        param.isUpdateUser = 1;
                        // 202003402 图片上传改为半路径
                        param.faceUrl = response.name;
                        faceApi.faceMod(param).then((res) => {
                            if (res.success) {
                                // 刷新当前的数据,
                                that.signUserInfo.srcUserInfo.faceUrl = response.url;
                                that.signUserInfo.faceUrl = response.url;

                                // 刷新列表中的人像地址
                                that.modUserDataInTableData(param.userId, that.signUserInfo.faceUrl);

                                HudGlobal.showInfoWithMessage("修改注册照成功!");
                            } else {
                                HudGlobal.showErrorWithMessage(res.description || "修改注册照失败!");
                            }
                        });
                    })
                    .catch((err) => {
                        HudGlobal.showErrorWithMessage(err || "修改注册照失败!");
                    });
            }
        },

        /*
         * 处理列表中学员/管理人员的人像信息
         * */
        modUserDataInTableData(userId, newFaceUrl) {
            for (let i = 0; i < this.tableData.length; i++) {
                if (userId === this.tableData[i].userId) {
                    this.tableData[i].faceUrl = newFaceUrl;
                }
            }

            // 修改开始上课的管理人员
            if (userId === this.planData.safeAdmin.userId) {
                this.planData.safeAdmin.faceUrl = newFaceUrl;
            }
        },

        /*
         * 上传图片到阿里云, uploadFaceType临时定义的一个, 主要是为了上传注册照, 目前只有1
         * */
        loadUploadImageRequest(file) {
            // 上传
            let storePath = "/user/reg/" + this.signUserInfo.srcUserInfo.orgId + "/";

            if (this.signUserInfo.srcUserInfo.userId) {
                storePath = storePath + this.signUserInfo.srcUserInfo.userId + "/";
            }
            return aliOssServe.uploadRegisterPhoto(file, storePath);
        },

        /**
         *  初始化摄像头
         */
        initWithCamera(elementId, width, height) {
            // 1.检测校验是- 设置默认值
            if (elementId == null || elementId.length === 0) {
                console.warn("请设置相机组件的id");
                return;
            }
            if (!width || width === 0 || width == null) {
                width = 180;
            }
            if (!height || height === 0 || height == null) {
                height = 229;
            }
            this.webcamConfig.width = width;
            this.webcamConfig.height = height;
            this.webcamConfig.dest_width = width;
            this.webcamConfig.dest_height = height;
            // this.webcamConfig.crop_width = width;
            // this.webcamConfig.crop_height = height;

            // 开启之前先关闭
            CameraUtil.closeCamera();

            // 2. 基本配置
            WebCamJS.set(this.webcamConfig);

            // 3. 组件配置
            const elment = "#" + elementId;
            WebCamJS.attach(elment);

            // 修正摄像头显示
            let _this = this;
            WebCamJS.on("error", function(err) {
                console.log("摄像头错误", err);
                if (!WebCamJS.container) {
                    // that.initWithCamera(that.config.elementId, that.config.width, that.config.height);
                    _this.initWithCamera("signInUserBox");

                    setTimeout(function() {
                        _this.takeSignInPhoto();
                    }, 222);
                }
            });
        },

        /*
         * 退出上课验证
         * */
        toClazzClose() {
            let _this = this;
            TibiMatomoManger.sendSignInTrackEvent(TrackConstants.eventNameKeys.sign_in_menu_exit);
            HudGlobal.showAlertConfirmMessage(`是否退出${this.getTrainTypeReplaceStr}验证`).then((res) => {
                let params = {};
                params.offlineRecordId = _this.offlineRecordParms.offlineRecordId;

                hoursApi.delTrainStart(params).then((res) => {
                    if (res.success) {
                        _this.startToClazz = false;
                    } else {
                        HudGlobal.showMessage(`退出${_this.getTrainTypeReplaceStr}失败, 请联系管理员!`);
                    }
                });
            });
        },

        /*
         * 去学习
         * */
        async toTrain() {
            TibiMatomoManger.sendSignInTrackEvent(TrackConstants.eventNameKeys.sign_in_menu_begin_train);
            TBDriver.resetSteps();
            let _this = this;
            if (this.signCtrlParam.offlineStartFaceAuthEnable == 1) {
                await HudGlobal.showAlertConfirmMessage(
                    `开始${_this.getTrainTypeReplaceStr}需要${_this.planData.safeAdmin.leaderName}验证后才能进行, 是否开始验证`
                ).then((res) => {
                    // 开始上课监督人安全管理员需要验证通过后开始 TODO 继续教育可能是授课人, 可能需要根据参数来判断是否上课
                    if (_this.planData.safeAdmin) {
                        _this.startTrain();
                    } else {
                        HudGlobal.showMessage("没有找到安全管理员!!!");
                    }
                });
            } else {
                // this.startToLesson();
                // fbz 2021-08-12
                this.startToClazz = true;
                this.checkStartToClazz("", _this.$store.getters.userId);
            }
        },
        /**
         * @description: 开始学习接口调用  0.10  fbz  2021-08-26
         * @param {*}
         * @return {*}
         */
        startTrain() {
            const _this = this;
            let params = {};
            params.offlineRecordId = _this.offlineRecordParms.offlineRecordId;
            _this.nextLoading = true;
            hoursApi
                .addTrainStart(params)
                .then((res) => {
                    _this.nextLoading = false;
                    if (res.success) {
                        _this.signUserInfo.srcUserInfo = _this.planData.safeAdmin;
                        _this.startToClazz = true;

                        this.signUserInfo.signInStatus = "请验证";
                        // 表格恢复
                        _this.tableOperateToInit();
                        HudGlobal.showAlertMessage("诚信学习，拒绝作弊，一经发现，学时清零！");
                    } else {
                        HudGlobal.showMessage(`开始${_this.getTrainTypeReplaceStr}失败, 请联系管理员!`);
                    }
                })
                .catch(() => {
                    _this.nextLoading = false;
                });
        },
        /*
         * 抽验后或不签退了继续学习
         * */
        toContinueTrain() {
            TibiMatomoManger.sendSignInTrackEvent(TrackConstants.eventNameKeys.sign_in_menu_continue_train);
            let _this = this;
            let tableData = this.tableData;
            if (this.signType.signType === 1) {
                if (tableData && tableData.length > 0) {
                    this.$confirm(`抽验未完成, 是否直接开始${this.getTrainTypeReplaceStr}`, "提示", {
                        confirmButtonText: "确定",
                        type: "warning",
                        closeOnClickModal: false,
                    }).then(() => {
                        _this.startToLesson();
                    });
                } else {
                    this.startToLesson();
                }
            } else if (this.signType.signType === 2) {
                if (tableData && tableData.length === 0) {
                    this.$confirm("所有学员已经均已签退完成, 不能继续学习, ", "提示", {
                        confirmButtonText: "确定",
                        type: "warning",
                        closeOnClickModal: false,
                    }).then(() => {
                        // 退出学习
                        this.toHomeList();
                    });
                } else {
                    this.startToLesson();
                }
            }
        },

        /*
         * 跳转上课页面
         * */
        startToLesson() {
            this.gotoLessonViewController();
        },

        /*
         * 关闭手机签到
         * */
        phoneSignInClose() {
            this.signInType.phoneSignIn = 1;
            this.signInType.phoneSignInName = "开启手机验证";
            // socketjs.close();
            // lzb 20200409 关闭签到不用关闭sokect 只是关闭二维码推送
            this.closeQrcodePush();
        },

        /*
         * 打开手机签到
         * */
        phoneSignInOpen() {
            this.signInType.phoneSignIn = 0;
            this.signInType.phoneSignInName = "关闭手机验证";

            this.signInForPhone();

            // lzb - 新增检测
            this.checkSafeManagerAddress();
        },

        /*
         * 签到方式控制
         * */
        handleSignInType(type) {
            TBDriver.resetSteps();
            let name = "";
            // type phone手机签到, pc电脑签到
            if (type === "phone") {
                name = TrackConstants.eventNameKeys.sign_in_menu_close_phone;
                if (this.signInType.phoneSignIn === 0) {
                    name = TrackConstants.eventNameKeys.sign_in_menu_close_phone;
                    this.phoneSignInClose();
                } else {
                    name = TrackConstants.eventNameKeys.sign_in_menu_open_phone;
                    this.phoneSignInOpen();
                }
            } else {
                if (this.signInType.pcSignIn === 0) {
                    name = TrackConstants.eventNameKeys.sign_in_menu_close_pc;
                    this.signInType.pcSignIn = 1;
                    this.signInType.pcSignInName = "开启电脑验证";
                    WebCamJS.reset();
                } else {
                    this.signInType.pcSignIn = 0;
                    this.signInType.pcSignInName = "关闭电脑验证";
                    name = TrackConstants.eventNameKeys.sign_in_menu_open_pc;
                    // 重新初始化签到照相, 延迟一点, 让DOM元素准备好
                    let _this = this;
                    setTimeout(function() {
                        _this.initWithCamera("signInUserBox");
                    }, 100);
                }
            }
            TibiMatomoManger.sendSignInTrackEvent(name);
        },

        /*
         * 签到
         * */
        signInStart(row, _this) {
            let name = TrackConstants.eventNameKeys.sign_in_menu_sign_in;
            if (this.signType.signType == 1) {
                // 抽验
                name = TrackConstants.eventNameKeys.sign_in_menu_spot_check;
            } else if (this.signType.signType == 2) {
                // 签退
                name = TrackConstants.eventNameKeys.sign_in_menu_sign_out;
            }
            TibiMatomoManger.sendSignInTrackEvent(name);
            let that = this;
            if (this.startToClazz) {
                let params = {};
                params.offlineRecordId = this.offlineRecordParms.offlineRecordId;

                hoursApi.delTrainStart(params).then((res) => {
                    if (res.success) {
                        that.signInStartDo(row, _this);
                    } else {
                        HudGlobal.showMessage(`退出${_this.getTrainTypeReplaceStr}失败, 请联系管理员!`);
                    }
                });
            } else {
                this.signInStartDo(row, _this);
            }
        },

        /*
         * 签到按钮的具体执行方法
         * */
        signInStartDo(row, _this) {
            this.startToClazz = false;
            if (this.signCtrlParam.isFaceVerify) {
                this.signUserInfo.srcUserInfo = row;

                this.tableOperateToInit();

                let signTypeName = this.getSignTypeName(this.signType.signType);

                let target = _this.currentTarget || _this.target;
                target.innerText = signTypeName + "中";
                this.signUserInfo.signInStatus = "请" + signTypeName;
            } else {
                // 不需要人脸识别签到, 直接调用签到接口, 不用传入人像
                this.signUserInfo.srcUserInfo = row;
                this.loadComparePhotoRequest();
            }
        },

        /*
         * 表格中的操作名称变为原始名称, 主要为签到
         * */
        tableOperateToInit() {
            let elementNodeListOf = document.querySelectorAll(".table-btn-sign-in-text");
            for (let i = 0; i < elementNodeListOf.length; i++) {
                let elementElement = elementNodeListOf[i];
                elementElement.innerText = this.signUserInfo.signInTableText;
            }
        },

        /*
         * 清除正在签到/签退/抽验/开始上课的进行中状态
         * */
        clearSignStatus() {
            this.signUserInfo.srcUserInfo = "";
            this.tableOperateToInit();
        },

        /*
         * 获取签到照片, 并开始签到
         * */
        takeSignInPhoto() {
            const that = this;
            TibiMatomoManger.sendSignInTrackEvent(TrackConstants.eventNameKeys.sign_in_menu_take_photo);
            // 如果是培训管理人员签退, 学员会被强制签退时, 提示
            let srcUserInfo = that.signUserInfo.srcUserInfo;
            if (that.signType.signType === 2 && srcUserInfo) {
                // lzb 2023-09-22 替换取值typeCode->identityCode
                if (
                    srcUserInfo.identityCode === Constants.organizeIndentityType.INDENTITY_SAFE_ADMIN &&
                    that.signCtrlParam.offlineAdminSignoutHoursEnable === 1
                ) {
                    // 安全管理员
                    that.$confirm("确认签退将结束此次培训，如果有未签退的学员，将强制签退！")
                        .then(() => {
                            that.faceAuth(that.signUserInfo.srcUserInfo);
                        })
                        .catch((err) => {
                            that.clearSignStatus();
                        });
                    // lzb 2023-09-22 替换取值typeCode->identityCode
                } else if (
                    srcUserInfo.identityCode === Constants.organizeIndentityType.INDENTITY_ASSESSORS &&
                    that.signCtrlParam.offlineAssessorsSignoutHoursEnable === 1
                ) {
                    // 考核人员
                    that.$confirm("确认签退将结束此次培训，如果有未签退的学员，将强制签退！")
                        .then(() => {
                            that.faceAuth();
                        })
                        .catch((err) => {
                            that.clearSignStatus();
                        });
                    // lzb 2023-09-22 替换取值typeCode->identityCode
                } else if (
                    srcUserInfo.identityCode === Constants.organizeIndentityType.INDENTITY_LECTURER &&
                    that.signCtrlParam.offlineLecturerSignoutHoursEnable === 1
                ) {
                    // 授课人
                    that.$confirm("确认签退将结束此次培训，如果有未签退的学员，将强制签退！")
                        .then(() => {
                            that.faceAuth();
                        })
                        .catch((err) => {
                            that.clearSignStatus();
                        });
                } else {
                    that.faceAuth();
                }
            } else {
                that.faceAuth();
            }
        },

        /*
         * 人脸认证(签到/抽验/签退/开始上课)
         * */
        faceAuth() {
            let srcUserInfo = this.signUserInfo.srcUserInfo;
            // 开始人脸认证
            if (this.startToClazz) {
                this.signUserInfo.signInStatus = "验证中...";
            } else {
                let signTypeName = this.getSignTypeName(this.signType.signType);
                this.signUserInfo.signInStatus = signTypeName + "中...";
            }

            // 获取照片
            // StringUtil.randomString(2) + console.log(StringUtil);
            let fileName = DateUtil.dateFtt("yyyyMMddhhmmss", new Date());

            const _this = this;
            this.takePhotoLoading = true;
            this.takeCameraSnapWithFileName(fileName)
                .then((res) => {

                    // 上传
                    let file = res.file;

                    _this.signUserInfo.signInPhoto = res;

                    

                    let storePath = "/user/verify/" + _this.planData.orgId + "/";
                    if (srcUserInfo.userId) {
                        storePath = storePath + srcUserInfo.userId + "/";
                    }

                    return aliOssServe.uploadVerifyPhoto(file, storePath);
                })
                .then((res) => {
                    // 上传结果处理
                    console.log("上传结果", res);
                    let relativeFileName = res.name;
                    // let fullUrl = res.url;

                    // 如果是验证则不需要处理签到等数据
                    let startToClazz = this.startToClazz;
                    if (startToClazz) {
                        this.checkStartToClazz(relativeFileName);
                    } else {
                        // 处理签到结果
                        this.loadComparePhotoRequest(relativeFileName);
                    }
                });
        },

        /*
         * 存储签到界面的操作类型, 0或空为签到, 1抽验, 2签退
         * */

        storeOfflineSignTye(signType) {
            if (signType != null) {
                StoreDBManger.saveDataToLocalStorage(StoreDBManger.storageKeys.SELECT_SIGN_TYPE, signType);
            }
        },

        /*
         * 验证是否可以上课
         * */
        checkStartToClazz(faceUrl, userId) {
            let params = {};
            // params.clientNumber = classroomId;
            params.offlineRecordId = this.planData.offlineRecordId;
            params.classroomId = this.planData.classroomId;
            params.planId = this.planData.planId;
            params.srcUrl = faceUrl;
            params.userId = this.signUserInfo.srcUserInfo.userId || userId;

            const _this = this;
            _this.nextLoading = true;
            hoursApi
                .checkOfflineToStart(params)
                .then((res) => {
                    // 拍照的等待去掉
                    _this.takePhotoLoading = false;
                    _this.nextLoading = false;

                    if (res.success) {
                        // 如果是开始上课验证, 那么完成后跳转上课页面
                        if (_this.startToClazz) {
                            _this.startToLesson();
                        }
                    } else {
                        // 安全管理员已签退 802232, 考核人员已签退 80233, 授课人已签退 80234
                        if (res.code === 802232 || res.code === 802232 || res.code === 802232) {
                            HudGlobal.showAlertConfirmMessages(res.description + ", 是否跳转至签退界面").then((res) => {
                                // 签到界面的操作类型, 0或空为签到, 1抽验, 2签退
                                _this.storeOfflineSignTye(2);
                                _this.initSignType();
                                _this.startToClazz = false;

                                // 获取现场培训基本信息
                                _this.getOfflineRecordDataOne();
                            });
                        } else {
                            // 监督人未签到
                            if (res.code * 1 === 80120) {
                                // 学员未签到
                                HudGlobal.showAlertConfirmMessages(res.description, "", "温馨提示", `开始${_this.getTrainTypeReplaceStr}`, "去签到")
                                    .then(() => {
                                        _this.startToLesson();
                                    })
                                    .catch(() => {
                                        _this.startToClazz = false;
                                        _this.clearSignStatus();
                                    });
                            } else {
                                HudGlobal.showAlertMessage(res.description || "管理员验证失败, 不能进行学习");
                                _this.startToClazz = false;
                                _this.clearSignStatus();
                            }
                        }
                    }

                    _this.signUserInfo.signInStatus = "请验证";
                })
                .catch(() => {
                    _this.nextLoading = false;
                });
        },

        /**
         * 照片比对
         */
        loadComparePhotoRequest(faceUrl) {
            // 签到参数处理
            this.signInForm.faceUrl = faceUrl;

            this.signInForm.orgId = this.planData.orgId;
            this.signInForm.userId = this.signUserInfo.srcUserInfo.userId;
            this.signInForm.realName = this.signUserInfo.srcUserInfo.realName;

            this.signInForm.planId = this.planData.planId;
            this.signInForm.offlineRecordId = this.planData.offlineRecordId;
            this.signInForm.classroomId = this.planData.classroomId;
            // 固定为确定签退  现场管理人员签退确认, 用在需要签退时学员一起签退, 0取消, 1确定, 如果无值默认为1
            this.signInForm.offlineSignOutConfirm = 1;

            // 处理签到/抽验/签退类别
            switch (this.signType.signType) {
                case 0:
                    this.signInForm.operationType = 10;
                    break;
                case 1:
                    this.signInForm.operationType = 21;
                    break;
                case 2:
                    // 签退要分不同人员签退
                    this.signInForm.operationType = 100;
                    break;
                default:
                    this.signInForm.operationType = 10;
            }

            let param = this.signInForm;

            const that = this;
            let signTypeName = that.getSignTypeName(this.signType.signType);
            hoursApi.hoursAddOrMod(param).then((res) => {
                console.log("签到/抽验/签退结果", res);
                that.takePhotoLoading = false;
                if (res.success) {
                    // 签到成功逻辑
                    that.signInForm.parameterMap = res.data.parameterMap;
                    that.signInForm.hoursRecordId = res.data.hoursRecordId;

                    that.signUserInfo.signInStatus = signTypeName + "成功";

                    // TODO 从当前用户列表中去掉现在的用户
                    if (that.signInForm.operationType === 10) {
                        // 签到重新加载数据, 获取签到记录
                        that.getLeadersUnSigninDataList();
                    } else if (that.signInForm.operationType === 21) {
                        // 获取抽验记录
                        that.getStudentUnsignInDataPage();
                    } else if (that.signInForm.operationType === 100) {
                        // 获取签退记录
                        that.getStudentUnsignInDataPage();
                    }
                } else {
                    if (that.startToClazz) {
                        that.signUserInfo.signInStatus = "验证失败";
                    } else {
                        let description = res.description;

                        let newSignStatusText;
                        if (description && description.length > 18) {
                            newSignStatusText = description.substring(0, 18);
                        } else {
                            newSignStatusText = description;
                        }
                        that.signUserInfo.signInStatus = newSignStatusText || signTypeName + "失败";
                    }
                    that.faceCompareTimes = that.faceCompareTimes + 1;
                    if (that.faceCompareTimes >= 3) {
                        //需要调用 另外的组件,修改注册照
                        //TODO 另外的组件,错误次数过多, 可以修改注册照
                    } else {
                        // 特殊处理, 暂时没有接口, 考核人员验证通过后可以开始学习, 80042, 正在学习,请勿重复签到
                        if (res.code === 80042) {
                            // 如果是开始上课验证, 那么完成后跳转上课页面
                            if (that.startToClazz) {
                                that.startToLesson();
                            }
                        } else if (res.code === 80045) {
                            that.showCheckFinishedConfirm(res.description || "您未在规定抽验时间内人脸验证，需重新签到学习", true);
                        } else if (res.code === 80109) {
                            this.$alert(`${res.description}`, "温馨提示", {
                                confirmButtonText: "确定",
                                showClose: false,
                                callback: () => {
                                    gotoHomeList({});
                                },
                            });
                        } else {
                            HudGlobal.showErrorWithMessage(res.description || signTypeName + "失败");
                        }
                    }
                }

                // 清除签到框
                that.clearSignStatus();
            });
        },

        /**
         * 开始拍照并返回
         */
        takeCameraSnapWithFileName(fileName) {
            return new Promise((resolve, reject) => {
                const that = this;
                WebCamJS.snap(function(data_base64, cavas, context) {
                    // console.log(cavas);
                    // console.log(context);
                    //返回data
                    that.__base64ToFile(data_base64, fileName || "file").then((file) => {
                        const item = {
                            base64: data_base64,
                            file: file,
                        };
                        if (resolve) {
                            resolve(item);
                        }
                    });
                });
            });
        },

        /**
         * base64转文件, 兼容IOS/MAC等, 放弃@dyb881/file
         * @param base64
         * @param fileName
         * @returns {Promise<Blob>}
         * @private
         */
        __base64ToFile(base64, fileName) {
            const data = base64.split(",");
            const type = data[0].slice(5, -7);
            const ext = type.split("/")[1];
            const bstr = atob(data[1]);
            let n = bstr.length;
            const u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            let blob = new Blob([u8arr], { type: type });
            blob.lastModifiedDate = new Date();
            blob.name = `${fileName}.${ext}`;
            return Promise.resolve(blob);
        },

        /*
         * 待签到/抽验/签退学员列表翻页
         * */
        currentPageChange(val) {
            this.studentListParams.currentPage = val;
            this.getStudentUnsignInDataPage();
        },

        /*
         * 已签到/抽验/签退学员列表翻页
         * */
        currentSignDoPageChange(val) {
            TibiMatomoManger.sendSignInTrackEvent(TrackConstants.eventNameKeys.sign_in_menu_record_current_page);
            this.signInLogList.currentPage = val;
            this.getStudentsignInDataPage();
        },

        /*
         * 学员查询
         * */
        studentSearch() {
            TBDriver.resetSteps();
            this.studentListParams.currentPage = 1;
            this.tableOperateToInit();
            this.getStudentUnsignInDataPage();
            TibiMatomoManger.sendSignInTrackEvent(TrackConstants.eventNameKeys.sign_in_menu_search_student);
        },

        /*
         * 查询学员未签到/抽验/签退记录
         * */
        getStudentUnsignInDataPage() {
            // 签到类型（0:待签到，10：已签到，20：抽验，30：签退）
            if (this.signType.signType === 0) {
                this.studentListParams.signType = 0;
            }

            if (this.signType.signType === 1) {
                // 状态（0：初始状态，1，失败，2：成功
                this.studentListParams.signType = 20;
                this.studentListParams.state = 0;
            }

            if (this.signType.signType === 2) {
                // 状态（0：初始状态，1，失败，2：成功
                this.studentListParams.signType = 10;
                this.studentListParams.state = 2;
            }

            this.studentListParams.operateCode = 0;
            this.getStudentDataPage(getSignInData);

            /* setTimeout(function () {
                   }, 300);*/
            let _this = this;

            function getSignInData() {
                // 查询已签到列表
                _this.loading = true;
                _this.getStudentsignInDataPage();
            }
        },

        /*
         * 查询学员签到记录
         * */
        getStudentsignInDataPage() {
            //TODO
            // 签到类型（0:待签到，10：已签到，20：抽验，30：签退）
            if (this.signType.signType === 0) {
                this.studentListParams.signType = 10;
            }

            if (this.signType.signType === 1) {
                // 状态（0：初始状态，1，失败，2：成功
                this.studentListParams.signType = 20;
                // this.studentListParams.state = 0;
                this.studentListParams.state = 2; // lzb 2020-09-10 待抽验进入页面-查看已经抽验的的数据 传2
                this.studentListParams.realNameOrCardNo = "";
            }

            if (this.signType.signType === 2) {
                // 状态（0：初始状态，1，失败，2：成功
                this.studentListParams.signType = 30;
                this.studentListParams.state = 2;
                this.studentListParams.realNameOrCardNo = "";
            }
            // this.studentListParams.currentPage = this.signInLogList.currentPage;
            // this.studentListParams.pageSize = this.signInLogList.pageSize;

            this.studentListParams.operateCode = 1;
            this.getStudentDataPage();
        },

        /**
         *  显示弹框失败弹框弹框
         */
        showCheckFinishedConfirm(message, isJumpLesson) {
            const that = this;
            this.$confirm(message, "提示", {
                confirmButtonText: "确定",
                type: "warning",
                closeOnClickModal: false,
            })
                .then(() => {
                    if (isJumpLesson) {
                        that.gotoLessonViewController();
                    }
                })
                .catch((res) => {
                    // 取消
                });
        },

        /*
         * 抽验提示
         * */
        signCheckFinishedTip(from, tipType, fromTip) {
            // 抽验完成提示弹窗提类型, 0无, 1所有抽验完成提示, 2当前抽验过期提示
            /*
                      1 2, 关闭之前的, 弹新提示
                      2 1, 不显示后面一个
                      0 2, 直接显示
                      0 1, 直接显示
                   */
            if (this.signCheckFinishedTipType !== 0) {
                if (tipType === 1 && this.signCheckFinishedTipType === 2) {
                    return false;
                } else {
                    this.signCheckFinishedTipType = 1;
                }

                if (tipType === 2) {
                    this.signCheckFinishedTipType = 2;

                    this.$confirm.close();
                }
            }

            let tip = `抽验完成, 开始${this.getTrainTypeReplaceStr}`;
            if (from === "no-data") {
                tip = `没有需要的抽验人员, 继续${this.getTrainTypeReplaceStr}`;
            }
            if (fromTip) {
                tip = fromTip;
            }

            // this.$confirm.closeAll();

            let _this = this;
            if (!this.signType.signCheckTip) {
                this.signType.signCheckTip = true;
                this.$confirm(tip, "提示", {
                    confirmButtonText: "确定",
                    type: "warning",
                    closeOnClickModal: false,
                })
                    .then(() => {
                        _this.signCheckFinishedTipType = 0;
                        _this.gotoLessonViewController();
                    })
                    .catch((res) => {
                        // 取消
                        _this.signCheckFinishedTipType = 0;
                    });
            }
        },

        /**
         * 跳转视频到上课页面
         */
        gotoLessonViewController() {
            let params = {};
            params.offlineRecordId = this.offlineRecordParms.offlineRecordId;

            this.$router.push({
                path: COMMON.TRAIN_VIDEO_STUDY,
                query: {
                    type: this.routeParam.type,
                    selectType: this.routeParam.meetingSelectType,
                    meetingCourseId: this.routeParam.meetingCourseId,
                    ...params,
                },
            });
        },

        /*
         * 签退完成提示并跳转首页
         * */
        signOutToHomeList() {
            if (!this.signOutToHomeListFun) {
                this.signOutToHomeListFun = true;
                this.$confirm("签退完成, 是否立即退出", "提示", {
                    confirmButtonText: "确定",
                    type: "warning",
                    showClose: false,
                    showCancelButton: false,
                    closeOnClickModal: false,
                }).then(() => {
                    this.toHomeList();
                });
            }
        },
        /*
         * 跳转首页
         * */
        toHomeList() {
            let params = {};
            gotoHomeList(params);
        },

        /**
         * 获取培训学员列表, 分页
         */
        getStudentDataPage(callbackFun) {
            let params = {};
            Object.assign(params, this.studentListParams);

            params.offlineRecordId = this.planData.offlineRecordId;
            params.userId = this.$store.getters.userId;

            if (this.studentListParams.operateCode === 1) {
                params.currentPage = this.signInLogList.currentPage;
                params.pageSize = this.signInLogList.pageSize;
            }
            // console.log("请求参数:", JSON.stringify(params))

            let _this = this;
            hoursApi.getOfflineUserList(params).then((res) => {
                console.log("获取学员 - 待签到/抽验/签退列表", res);
                _this.loading = false;

                if (res.success && res.data) {
                    let data = res.data;
                    // 未签到记录
                    if (
                        _this.studentListParams.signType === 0 ||
                        (_this.studentListParams.signType === 10 && _this.signType.signType === 2) ||
                        (_this.studentListParams.signType === 20 && _this.studentListParams.state === 0)
                    ) {
                        _this.tableDataTotal = res.total;
                        _this.staticObject.waitOperationStudentCount = res.total;

                        // 只有第一页附加管理人员数据
                        _this.tableData = [];
                        if (_this.studentListParams.currentPage === 1) {
                            _this.tableData = _this.tableData.concat(_this.tableLeadersData);
                        }
                        _this.tableData = _this.tableData.concat(data);
                        console.log("学员未签到记录", _this.tableData);

                        // 抽验需要处理一下validateNumber
                        if (_this.tableData && _this.tableData.length > 0) {
                            let tableDatum = _this.tableData[0];
                            if (tableDatum && tableDatum.validateNumber) {
                                _this.studentListParams.validateNumber = tableDatum.validateNumber;
                            }
                        }
                    } else if (
                        _this.studentListParams.signType === 10 ||
                        (_this.studentListParams.signType === 20 && _this.studentListParams.state === 2) ||
                        (_this.studentListParams.signType === 30 && _this.studentListParams.state === 2)
                    ) {
                        // 已经签到统计
                        _this.staticObject.finishOperationCount = res.total;
                        _this.signInLogList.list = [];
                        _this.signInLogList.list = _this.signInLogList.list.concat(data);
                        console.log("学员已签到记录", _this.signInLogList.list);

                        // 签到时才有签到总数
                        _this.signInLogList.signInNumber = res.total;

                        // 抽验逻辑
                        // if (_this.studentListParams.signType === 20 && _this.studentListParams.state === 2) {
                        //     setTimeout(function () {
                        //         _this.signCheckFinishedTip();
                        //     }, 20)
                        // }
                    }

                    // 重新初始化签到表格
                    _this.tableOperateToInit();
                } else {
                    if (res.code !== 102) {
                        HudGlobal.showMessage(res.description || "获取培训学员数据失败!");
                    } else {
                        // 未签到记录
                        if (
                            _this.studentListParams.signType === 0 ||
                            (_this.studentListParams.signType === 10 && _this.signType.signType === 2) ||
                            (_this.studentListParams.signType === 20 && _this.studentListParams.state === 0)
                        ) {
                            // 只有第一页附加管理人员数据
                            _this.tableData = [];
                            _this.tableDataTotal = 0;
                            if (_this.studentListParams.currentPage === 1) {
                                _this.tableData = _this.tableData.concat(_this.tableLeadersData);
                            }

                            // 抽验逻辑
                            if (_this.studentListParams.signType === 20 && _this.studentListParams.state === 0) {
                                setTimeout(function() {
                                    _this.signCheckFinishedTip("no-data");
                                }, 99);
                            }

                            // 签退逻辑
                            if (_this.studentListParams.signType === 10 && _this.studentListParams.state === 2) {
                                _this.signOutToHomeList();
                            }
                        } else if (
                            _this.studentListParams.signType === 10 ||
                            (_this.studentListParams.signType === 20 && _this.studentListParams.state === 2) ||
                            (_this.studentListParams.signType === 30 && _this.studentListParams.state === 2)
                        ) {
                            _this.signInLogList.list = [];
                        }
                    }
                }

                // // 引导
                setTimeout(function() {
                    _this.checkLivelearningOperationGuide();
                }, 300);

                if (callbackFun) {
                    callbackFun();
                }
            });
        },

        /*
         * 获取培训管理人员及学员未签到列表
         * */
        getLeadersUnSigninDataList() {
            this.leadersListParams.state = 10;
            this.getLeadersDataList();
        },

        /*
         * 获取培训管理人员及学员签退列表
         * */
        getLeadersSignoutDataList() {
            this.leadersListParams.state = 30;
            this.getLeadersDataList();
        },

        /*
         * 获取培训管理人员列表
         * */
        getLeadersDataList() {
            let params = this.leadersListParams;
            params.offlineRecordId = this.planData.offlineRecordId;
            params.planId = this.planData.planId;
            let _this = this;
            _this.tableLeadersData = [];
            hoursApi.getOfflineLeaderList(params).then((res) => {
                if (res.success && res.data) {
                    let data = res.data;
                    if (params.state === 10) {
                        // 签到列表附加

                        // _this.tableLeadersSrcData = _this.tableLeadersSrcData.concat(data);
                        _this.tableLeadersSrcData = data || [];
                        if (_this.signCtrlParam.offlineLeaderJoinSignEnable == 1) {
                            // 合并身份
                            _this.joinedLeadersData(data);
                        } else {
                            // 不合并
                            _this.tableLeadersData = _this.tableLeadersSrcData;
                        }
                        console.log("培训管理员-未签到数据", _this.tableLeadersData);
                        // 统计管理员人数
                        _this.staticObject.waitOperationLeaderCount = _this.tableLeadersData.length;
                        _this.getStudentUnsignInDataPage();
                    }
                } else {
                    if (res.code !== 102) {
                        HudGlobal.showMessage(res.description || "获取培训管理人员数据失败!");
                    } else {
                        // 获取签到人员列表时, 获取学员列表
                        if (params.state === 10) {
                            // 签到列表附加
                            _this.getStudentUnsignInDataPage();
                        }
                    }
                }
            });
        },
        /*
         * 数据处理
         * */
        joinedLeadersData(data) {
            // 如果是同一个管理员进行数据合并
            let newLeaderData = [];
            for (let i = 0; i < data.length; i++) {
                let datum = data[i];
                if (i === 0) {
                    newLeaderData.push(datum);
                } else {
                    // 是否是同一个培训管理者
                    let isSame = false;
                    for (let j = 0; j < newLeaderData.length; j++) {
                        let newLeaderDatum = newLeaderData[j];

                        if (newLeaderDatum.userId === datum.userId) {
                            isSame = true;
                            newLeaderDatum.identityName = newLeaderDatum.identityName + "," + datum.identityName;
                        }
                    }

                    if (!isSame) {
                        newLeaderData.push(datum);
                    }
                }
            }

            this.tableLeadersData = [];
            this.tableLeadersData = this.tableLeadersData.concat(newLeaderData);
        },

        /**
         * 初始化配置参数
         */
        setupInitPlanParam(planParam) {
            // 处理页面参数
            Object.assign(this.signCtrlParam, planParam);

            // 初始化SOCKET连接, 根据用户
            this.initSocket();

            this.signInType.pcSignIn = this.signCtrlParam.faceLiveDetectionPc == 1 ? 0 : 1;
            this.signInType.phoneSignIn = this.signCtrlParam.faceLiveDetectionApp == 1 ? 0 : 1;
        },

        /*
         * 获取单个培训历史记录ID, 上个页面需要传入历史记录ID
         * */
        getOfflineRecordDataOne() {
            let params = this.offlineRecordParms;

            let _this = this;
            planApi.getOfflineRecordOne(params).then((res) => {
                if (res.success) {
                    let data = res.data;
                    // 处理培训管理人员
                    let offlinePlanLeaderDtoList = data.offlinePlanLeaderDtoList;
                    data.safeAdmin = offlinePlanLeaderDtoList[0];
                    // if (offlinePlanLeaderDtoList) {
                    //     for (let i = 0; i < offlinePlanLeaderDtoList.length; i++) {
                    //         let leader = offlinePlanLeaderDtoList[i];
                    //         // 只是需要安全管理V1.0.9
                    //         if (leader.typeCode === Constants.organizeIndentityType.INDENTITY_SAFE_ADMIN) {
                    //             data.safeAdmin = leader;
                    //         }else if (leader.typeCode === Constants.organizeIndentityType.INDENTITY_LECTURER) {
                    //             // data.lecturer = leader;
                    //             // data.lecturer = leader;
                    //         }else if (leader.identityCode === Constants.organizeIndentityType.INDENTITY_ASSESSORS) {
                    //             // data.auditor = leader;
                    //         }
                    //     }
                    // }

                    // 计算多少人未培训 planData.planDto.userCount - planData.planDto.finishCount
                    let planDto = data.planDto;
                    if (planDto) {
                        let userCount = planDto.userCount;
                        let studyFinishCount = planDto.studyFinishCount;
                        planDto.restCount = userCount - studyFinishCount;
                    }

                    // 学员签到数据查询参数
                    _this.studentListParams.planId = data.planId;
                    _this.studentListParams.classroomId = data.classroomId;

                    // lzb 2020-04-23 保存记录id
                    setOfflineRecordIdToDB(data.offlineRecordId);
                    _this.planData = data;

                    // 签到, 抽验, 签退, 抽验不需要查询培训管理人员
                    // 参数获取完后处理逻辑  需要在回调之后处理数据 yxl 20230316
                    StoreTrainDBManger.getCurrentPlanItemFromDB(function(planItem) {
                        if (!planItem) {
                            OfflineParam.init(planDto.planId, function() {
                                // 签到, 抽验, 签退, 抽验不需要查询培训管理人员
                                if (_this.signType.signType === 1 || _this.signType.signType === 2) {
                                    _this.getStudentUnsignInDataPage();
                                } else {
                                    _this.getLeadersUnSigninDataList();
                                }
                                _this.setupInitPlanParam(OfflineParam.params);
                            });
                        } else {
                            // 签到, 抽验, 签退, 抽验不需要查询培训管理人员
                            if (_this.signType.signType === 1 || _this.signType.signType === 2) {
                                _this.getStudentUnsignInDataPage();
                            } else {
                                _this.getLeadersUnSigninDataList();
                            }
                        }
                    });

                    // 检测安全员是否需要校验地址 2020-08-10 lzb
                    _this.checkSafeManagerAddress();
                } else {
                    if (res.code !== 102) {
                        HudGlobal.showMessage(res.description || "获取培训记录信息失败!");
                    } else {
                    }
                }
            });
        },

        /**
         * 检测安全员是否需要校验地址 2020-08-10 lzb
         */
        checkSafeManagerAddress() {
            // 1. 参数检测
            if (!this.signCtrlParam.isFaceVerify || this.signCtrlParam.faceLiveDetectionApp != 1) {
                return false;
            }

            // 2. 字段 phoneSignIn = 0 是已经开启手机
            if (this.signInType.phoneSignIn != 0) {
                return false;
            }

            // 3. 接口检测安全员位置
            this.loadClassRoomAddress(this.studentListParams.classroomId, function() {
                HudGlobal.showWarningWithMessage("安全管理请扫描更新培训位置");
            });
        },

        /*
         * 退出现场, 准备签退 fbz 1.0.14需求 2021-09-16
         * */
        logoutDo() {
            const that = this;
            this.loadFinishSignInStudentData(function(count, msg, isContainSupervisor) {
                // 所有人员签退完成
                if (count === 0 && !isContainSupervisor) {
                    const query = getQueryStringParams();
                    const type = query.type ? query.type : getCurrentSystemType();

                    const message = type == SYSTEM_TYPE.MEETING ? "您是否退出, 返回到会议列表" : "您是否退出, 返回到计划列表";
                    HudGlobal.showAlertConfirmMessage(message).then(() => {
                        gotoHomeList({});
                    });
                } else if (count === 0 && isContainSupervisor) {
                    // 不含学员身份的未签退
                    HudGlobal.showAlertConfirmMessages(msg, "", "温馨提示", "确定退出", "去签退", true)
                        .then(() => {
                            gotoHomeList({});
                        })
                        .catch((action) => {
                            // 0或空为签到, 1抽验, 2签退
                            if (action === "cancel") {
                                StoreDBManger.saveDataToLocalStorage(StoreDBManger.storageKeys.SELECT_SIGN_TYPE, 2);
                                location.reload();
                            }
                        });
                } else {
                    // 包含学员的未签退
                    that.logOutDialog.logOutDialogVisible = true;
                    that.logOutDialog.msg = msg;
                }
            });
        },

        /**
         * 加载已经签到的学员数量
         */
        loadFinishSignInStudentData(callBackFunc) {
            let params = {};
            Object.assign(params, this.studentListParams);

            // params.identityCode = Constants.organizeIndentityType.INDENTITY_STUDENT;
            params.offlineRecordId = this.planData.offlineRecordId;
            params.userId = this.$store.getters.userId;
            params.signType = 10; // 已经签到

            const that = this;
            hoursApi.getOfflineUserList(params).then((res) => {
                let count = 0,
                    msg = "";
                let isContainSupervisor = false; // 是否包含监督员
                // lzb V1.0.9 2021-06-17 后台说只是需要统计学员
                if (res && res.success && res.data) {
                    res.data.map((item) => {
                        if (item.identityCode == Constants.organizeIndentityType.INDENTITY_STUDENT) {
                            count = count + 1;
                        }
                    });
                    let tempMsg = "";
                    res.data
                        .filter((item) => {
                            if (item.identityCode !== Constants.organizeIndentityType.INDENTITY_STUDENT) {
                                return item;
                            }
                        })
                        .map((item) => {
                            tempMsg += `1个${item.identityName}`;
                            isContainSupervisor = true;
                        });
                    if (count > 0) {
                        msg = `本次培训您还有${count}个学员${tempMsg}未签退，请谨慎退出！`;
                    } else {
                        msg = `本次培训您还有${tempMsg}未签退, 请谨慎退出！`;
                    }
                } else {
                    count = 0;
                    msg = "";
                }
                if (callBackFunc) {
                    callBackFunc(count, msg, isContainSupervisor);
                }
            });
        },
        /**
         * 获取教室的经纬度 - lzb 2020-08-10
         */
        loadClassRoomAddress(classroomId, callBack) {
            const param = {
                selfId: classroomId,
            };
            const that = this;
            hoursApi.getClassroomOne(param).then((res) => {
                if (res && res.success) {
                    // 组织id 与项目的计划id 再回调提示
                    const orgId = res.data.orgId;
                    if (orgId && orgId == that.studentListParams.planId) {
                        if (callBack) {
                            callBack();
                        }
                    }
                } else {
                }
            });
        },

        //===============================================数据处理==================================//
        /**
         * 过滤数据 通过身份和userid
         */
        filterArrayListByUser(soureList, user) {
            if (!soureList || soureList.length == 0 || user == null) {
                return [];
            }
            const resultList = [];
            Object.assign(resultList, soureList);
            for (let i = 0; i < resultList.length; i++) {
                const item = resultList[i];
                // 如果身份和userid都相等
                // 有typeCode值表示是监督员
                const param = item.typeCode ? "typeCode" : "identityCode";
                //  lzb 2021-07-22  user里面固定是 identityCode  item里面根据参数不同而不同 学员 identityCode  管理员 typeCode
                if (item.userId == user.userId && item[param] == user.identityCode) {
                    resultList.splice(i, 1);
                    break;
                }
            }
            return resultList;
        },
        /**
         * 计算待操作的数据
         */
        computeUserCountArrayListByUser(soureList, user) {
            if (!soureList || soureList.length == 0 || user == null) {
                return;
            }
            const resultList = [];
            Object.assign(resultList, soureList);
            for (let i = 0; i < resultList.length; i++) {
                const item = resultList[i];
                // 如果身份和userid都相等
                const param = item.typeCode ? "typeCode" : "identityCode";
                if (item.userId == user.userId && item[param] == user.identityCode) {
                    // 如果身份是学员
                    if (item.identityCode == Constants.organizeIndentityType.INDENTITY_STUDENT) {
                        this.staticObject.waitOperationStudentCount--;
                        if (this.staticObject.waitOperationStudentCount <= 0) {
                            this.staticObject.waitOperationStudentCount = 0;
                        }
                    } else {
                        this.staticObject.waitOperationLeaderCount--;
                        if (this.staticObject.waitOperationLeaderCount <= 0) {
                            this.staticObject.waitOperationLeaderCount = 0;
                        }
                    }
                    break;
                }
            }
        },
        closeDialogCamera() {
            require("@tibi/webcam");
            let _this = this;
            setTimeout(function() {
                _this.initWithCamera("signInUserBox");
            }, 100);
        },

        // ============================================操作引导 ==============================================//
        /**
         * 初始化操作引导
         * */
        getOperationGuideList() {
            // 0或空为签到, 1抽验, 2签退
            if (this.signType.signType == 1) {
                // 抽验
                let list = ConstantsGuideCode.loginSamplingTrainGuideCodes.split(",");
                this.operationGuideListId = list;
            } else if (this.signType.signType == 2) {
                // 签退
                let list = ConstantsGuideCode.loginOutTrainGuideCodes.split(",");
                this.operationGuideListId = list;
            } else {
                let list = ConstantsGuideCode.loginTrainGuideCodes.split(",");
                this.operationGuideListId = list;
            }
            console.log("operationGuideListId", this.operationGuideListId);
        },

        /**
         * 上传配置 2023-04-12
         */
        loadUploadConfigRequest() {
            const orgId = this.$store.getters.orgId;
            aliOssServe.getUploadPlatformConfig(orgId).then((data) => {
                store.dispatch("setUploadPlatform", data);
                window.$TBUpload.uploadPlatform = data || EnumConstants.UPLOAD_PLATFORM.HUAWEI;
            });
        },

        /**
         * 检测操作引导
         */
        checkLivelearningOperationGuide() {
            if (this.isFristEntry) {
                this.isFristEntry = false;

                // 0或空为签到, 1抽验, 2签退
                if (this.signType.signType == 1) {
                    // 抽验
                    this.signSpotCheckOperationGuide();
                } else if (this.signType.signType == 2) {
                    // 签退
                    this.signOutCheckOperationGuide();
                } else {
                    // 签到
                    this.signInCheckOperationGuide();
                }
            }
        },
        /**
         * 签退检测操作引导
         */
        signOutCheckOperationGuide() {
            const that = this;
            GuideStepHelper.loadOperationGuideData(ConstantsGuideCode.loginOutTrainGuideCodes, function(dataList) {
                if (dataList == null || dataList.length == 0) {
                    return;
                }
                for (let i = 0; i < dataList.length; i++) {
                    const guide = dataList[i];
                    //是否已完成引导(0:未完成,1:已完成)
                    if (guide.guideDone == 0) {
                        const keyCode = guide.guideCode;

                        if (keyCode == "step211") {
                            TBDriver.addDriverStep(
                                HomeGuideSteps.createLivelearningGuideSteps(
                                    "#step211",
                                    "学员、监督人签退",
                                    "学员点击【签退】<br> 考核人员点击【签退】 <br> 安全管理员点击【签退】",
                                    null,
                                    211,
                                    211,
                                    false,
                                    true,
                                    false,
                                    ConstantsGuideCode.loginOutTrainGuideCodes
                                )
                            );
                        }
                        if (keyCode == "step212") {
                            TBDriver.addDriverStep(
                                HomeGuideSteps.createLivelearningGuideSteps(
                                    "#step212",
                                    "手机扫描签退",
                                    "学员、监督人员均可扫码签退",
                                    null,
                                    212,
                                    212,
                                    false,
                                    true,
                                    that.isHasLastStep(dataList, guide),
                                    ConstantsGuideCode.loginOutTrainGuideCodes
                                )
                            );
                        }
                        if (keyCode == "step213") {
                            TBDriver.addDriverStep(
                                HomeGuideSteps.createLivelearningGuideSteps(
                                    "#step213",
                                    "拍照验证",
                                    "点击【拍照验证】，左侧注册照和右侧人像进行验证",
                                    null,
                                    213,
                                    213,
                                    false,
                                    true,
                                    that.isHasLastStep(dataList, guide),
                                    ConstantsGuideCode.loginOutTrainGuideCodes
                                )
                            );
                        }

                        if (keyCode == "step214") {
                            TBDriver.addDriverStep(
                                HomeGuideSteps.createLivelearningGuideSteps(
                                    "#step214",
                                    "签退记录",
                                    "签退记录会显示签退成功的人员",
                                    null,
                                    214,
                                    214,
                                    false,
                                    true,
                                    that.isHasLastStep(dataList, guide),
                                    ConstantsGuideCode.loginOutTrainGuideCodes
                                )
                            );
                        }
                        if (keyCode == "step215") {
                            TBDriver.addDriverStep(
                                HomeGuideSteps.createLivelearningGuideSteps(
                                    "#step215",
                                    "退出现场学习",
                                    "学员全部签退完成后，点击【退出现场学习】，结束此次培训",
                                    null,
                                    215,
                                    215,
                                    true,
                                    false,
                                    that.isHasLastStep(dataList, guide),
                                    ConstantsGuideCode.loginOutTrainGuideCodes,
                                    "#409EFF"
                                )
                            );
                        }
                        TBDriver.startDriver();
                    }
                }
            });
        },

        /**
         * 抽验检测操作引导
         */
        signSpotCheckOperationGuide() {
            const that = this;
            GuideStepHelper.loadOperationGuideData(ConstantsGuideCode.loginSamplingTrainGuideCodes, function(dataList) {
                if (dataList == null || dataList.length == 0) {
                    return;
                }
                for (let i = 0; i < dataList.length; i++) {
                    const guide = dataList[i];
                    //是否已完成引导(0:未完成,1:已完成)
                    if (guide.guideDone == 0) {
                        const keyCode = guide.guideCode;

                        if (keyCode == "step216") {
                            TBDriver.addDriverStep(
                                HomeGuideSteps.createLivelearningGuideSteps(
                                    "#step216",
                                    "学员抽验",
                                    "学员点击【抽验】，列表中的学员均需要抽验",
                                    null,
                                    216,
                                    216,
                                    false,
                                    true,
                                    false,
                                    ConstantsGuideCode.loginSamplingTrainGuideCodes
                                )
                            );
                        }

                        if (keyCode == "step217") {
                            TBDriver.addDriverStep(
                                HomeGuideSteps.createLivelearningGuideSteps(
                                    "#step217",
                                    "手机扫码抽验",
                                    "学员可以扫码抽验",
                                    null,
                                    217,
                                    217,
                                    false,
                                    true,
                                    that.isHasLastStep(dataList, guide),
                                    ConstantsGuideCode.loginSamplingTrainGuideCodes
                                )
                            );
                        }

                        if (keyCode == "step218") {
                            TBDriver.addDriverStep(
                                HomeGuideSteps.createLivelearningGuideSteps(
                                    "#step218",
                                    "拍照验证",
                                    "点击【拍照验证】，左侧注册照和右侧人像进行验证",
                                    null,
                                    218,
                                    218,
                                    false,
                                    true,
                                    that.isHasLastStep(dataList, guide),
                                    ConstantsGuideCode.loginSamplingTrainGuideCodes
                                )
                            );
                        }

                        if (keyCode == "step219") {
                            TBDriver.addDriverStep(
                                HomeGuideSteps.createLivelearningGuideSteps(
                                    "#step219",
                                    "抽验记录",
                                    "抽验记录会显示抽验成功的人员",
                                    null,
                                    219,
                                    219,
                                    false,
                                    true,
                                    that.isHasLastStep(dataList, guide),
                                    ConstantsGuideCode.loginSamplingTrainGuideCodes
                                )
                            );
                        }
                        if (keyCode == "step220") {
                            const str = that.getTrainTypeReplaceStr;
                            TBDriver.addDriverStep(
                                HomeGuideSteps.createLivelearningGuideSteps(
                                    "#step220",
                                    `继续${str}`,
                                    `学员抽验完成后，点击【继续${str}】继续学习`,
                                    null,
                                    220,
                                    220,
                                    true,
                                    false,
                                    that.isHasLastStep(dataList, guide),
                                    ConstantsGuideCode.loginSamplingTrainGuideCodes
                                )
                            );
                        }
                    }
                }
                TBDriver.startDriver();
            });
        },

        /**
         * 签到检测操作引导
         */
        signInCheckOperationGuide() {
            // 签到
            const that = this;
            GuideStepHelper.loadOperationGuideData(ConstantsGuideCode.loginTrainGuideCodes, function(dataList) {
                if (dataList == null || dataList.length == 0) {
                    return;
                }
                for (let i = 0; i < dataList.length; i++) {
                    const guide = dataList[i];
                    //是否已完成引导(0:未完成,1:已完成)
                    if (guide.guideDone == 0) {
                        const keyCode = guide.guideCode;

                        if (keyCode == "step206") {
                            TBDriver.addDriverStep(
                                HomeGuideSteps.createLivelearningGuideSteps(
                                    "#step206",
                                    "监督人，学员签到",
                                    "安全管理员点击【签到】<br>考核人员点击【签到】<br>学员点击【签到】",
                                    null,
                                    206,
                                    206,
                                    false,
                                    true,
                                    false,
                                    ConstantsGuideCode.loginTrainGuideCodes
                                )
                            );
                        }
                        if (keyCode == "step207") {
                            TBDriver.addDriverStep(
                                HomeGuideSteps.createLivelearningGuideSteps(
                                    "#step207",
                                    "手机扫描签到",
                                    "监督人员或学员均可扫码签到",
                                    null,
                                    207,
                                    207,
                                    false,
                                    true,
                                    that.isHasLastStep(dataList, guide),
                                    ConstantsGuideCode.loginTrainGuideCodes
                                )
                            );
                        }
                        if (keyCode == "step208") {
                            TBDriver.addDriverStep(
                                HomeGuideSteps.createLivelearningGuideSteps(
                                    "#step208",
                                    "拍照验证",
                                    "点击【拍照验证】，左侧注册照和右侧人像进行验证",
                                    null,
                                    208,
                                    208,
                                    false,
                                    true,
                                    that.isHasLastStep(dataList, guide),
                                    ConstantsGuideCode.loginTrainGuideCodes
                                )
                            );
                        }

                        if (keyCode == "step209") {
                            TBDriver.addDriverStep(
                                HomeGuideSteps.createLivelearningGuideSteps(
                                    "#step209",
                                    "签到记录",
                                    "签到记录会显示签到成功的人员",
                                    null,
                                    209,
                                    209,
                                    false,
                                    true,
                                    that.isHasLastStep(dataList, guide),
                                    ConstantsGuideCode.loginTrainGuideCodes
                                )
                            );
                        }
                        if (keyCode == "step210") {
                            const str = that.getTrainTypeReplaceStr;
                            TBDriver.addDriverStep(
                                HomeGuideSteps.createLivelearningGuideSteps(
                                    "#step210",
                                    `开始${str}`,
                                    `学员签到完成后，点击【开始${str}】进行学习`,
                                    null,
                                    210,
                                    210,
                                    true,
                                    false,
                                    that.isHasLastStep(dataList, guide),
                                    ConstantsGuideCode.loginTrainGuideCodes
                                )
                            );
                        }
                        TBDriver.startDriver();
                    }
                }
            });
        },
        /**
         * 是有具有上一步 - lzb 20200903
         */
        isHasLastStep(dataList, codeItem) {
            if (!dataList || dataList.length == 0) {
                return false;
            }
            if (!codeItem) {
                return false;
            }

            const index = dataList.indexOf(codeItem);
            const computeList = dataList.filter((item, idx) => {
                return idx < index;
            });
            if (!computeList || computeList.length == 0) {
                return false;
            }
            for (let i = 0; i < computeList.length; i++) {
                const item = computeList[i];
                if (item.guideDone == 0) {
                    return true;
                }
            }
            return false;
        },
        // 获取图片限制大小
        getImageSize(){
            // return new Promise((resolve, reject)=> {
            //  const that = this;
            //  const param = {
            //      orgId: that.signUserInfo.srcUserInfo.orgId,
            //      parameterCode: FACE.STUDENT_FACE_IMAGE_SIZE_LIMIT,
            //  }
            //  sys.getParameterCodeAdminList(param).then(res => {
            //      // 人脸图片限制 lzb20240805
            //      if(res.success && res.data) {
            //         if (res.data[STUDENT.STUDENT_FACE_IMAGE_SIZE_LIMIT]) {
            //             const limitList = JSON.parse(res.data[FACE.STUDENT_FACE_IMAGE_SIZE_LIMIT]) || [];
            //             if (limitList && limitList.length > 0) {
            //                 limitList.forEach((item) => {
            //                     if (item.code == "face_image_size_limit_min") {
            //                         that.faceImageLimit.min = parseInt(item.content);
            //                     }
            //                     if (item.code == "face_image_size_limit_max") {
            //                         that.faceImageLimit.max = parseInt(item.content);
            //                     }
            //                 });
            //             }
            //         }
            //      }
            //   })
            // });
          },

        // methods end
    },

    /*
     * 计算属性
     * */
    computed: {
        ...mapState({
            parameterCodeList: (state) => {
                return state.app.parameterCodeList;
            },
        }),
        /**
         * 获取代签到人员的统计数据
         */
        getLeftSignCount() {
            const count = this.staticObject.waitOperationStudentCount + this.staticObject.waitOperationLeaderCount;
            return count;
        },
        /**
         * 获取已经签到的统计数据
         */
        getRightSignCount() {
            const count = this.staticObject.finishOperationCount;
            return count;
        },
        /**
         * 获取学员数量 - 拥有学员
         */
        getStudentCount() {
            const count = this.staticObject.waitOperationStudentCount;
            return count;
        },
        /**
         * 是否是现场会议
         */
        isOfflineMeeting() {
            return getCurrentSystemType() == SYSTEM_TYPE.MEETING;
        },
        /**
         * 文字替换 会议与上课
         */
        getTrainTypeReplaceStr() {
            return this.isOfflineMeeting ? "会议" : "上课";
        },
    },
};
